.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
}

header {
  background-color: #069;
  color: white;
  padding: 10px;
  text-align: center;
}

footer {
  background-color: #069;
  color: white;
  margin-top: 10px;
  align-items: center; /* Vertically center the content */
  text-align: center;
  padding: 5px; /* Adds padding for some breathing room */
  font-size: 10px;
}

.kofiButton {
  position: fixed;
  top: 40px;
  right: 20px;
}

nav {
  background-color: #000;
  padding: 10px;
}

nav .separator {
    color: white;
}

nav a {
    display: inline-block; /* Or flex, block, etc., depending on your layout */
    padding: 5px 10px; /* Adjust padding as needed */
    text-decoration: none; /* Optional: removes the underline from links */
    color: white; /* Set your text color */
    background-color: black; /* Set initial background color */
    transition: background-color 0.3s; /* Smooth transition for the background color */
}

nav a:hover {
    background-color: #993399; /* Background color on hover */
}

.scrollToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  font-size: 24px;
  border-radius: 50%;
  background-color: #069;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  display: none; /* Initially hidden */
}

.scrollToTopButton.show {
  display: block; /* Show button when needed */
}

.home-content h3 {
  cursor: pointer;
}

.stats-table-container {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}

.stats-table-container table {
  margin-right: 10px
}

.collapsible-content {
  padding: 20px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.table-container {
    overflow-x: auto;
    width: 100%; /* Full width */
}

table {
    //width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: right;
}

th {
    background-color: #333;
    color: white;
    text-align: center;
}

/* Style for the first column */
td:first-child, th:first-child {
    background-color: #333;
    color: white;
    text-align: center;
}

td.error-cell {
    background-color: #F4C7C3;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

tr.error-cell {
    background-color: #F4C7C3;
}


/* Sticky header and first column */
th:first-child, td:first-child {
    text-align: right;
    position: sticky;
    left: 0;
    z-index: 1;
}

.page-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
}

/* Container for both estate and block info */
.estate-block-info-container {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    gap: 10px;
    width: 100%; /* Full width */
}

/* Common styles for estate-info and block-info */
.estate-info, .block-info, .premise-info {
    background-color: #993399; /* Background color */
    color: white; /* Font color */
    padding: 10px;
    border-radius: 5px; /* Optional: for rounded corners */
    width: 45%; /* Adjust width as needed */
}

.first-block {
    background-color: #993399; /* Background color */
    border-radius: 5px; /* Optional: for rounded corners */
}

.second-block {
    background-color: #069; /* Background color */
    border-radius: 5px; /* Optional: for rounded corners */
}

/* Styles for lists within estate-info and block-info */
.estate-info ul, .block-info ul, .premise-info ul {
    list-style-type: none; /* Removes bullet points */
    padding: 0;
}

/* Styles for dropdown */
select {
    background-color: white; /* Background color for dropdown */
    border: 1px solid #ddd; /* Flat border style */
    color: #333; /* Font color */
    padding: 5px 10px;
    border-radius: 4px; /* Optional: for rounded corners */
    width: 100%; /* Optional: makes the dropdown full width */
    box-sizing: border-box; /* Ensures padding doesn't affect overall width */
}

/* Optional: Style for <option> elements */
select option {
    background-color: white;
    color: #333;
}

.home-content-container {
    display: grid;
    grid-template-columns: 1fr; /* Single column layout */
    justify-items: center; /* Center grid items horizontally */
    gap: 20px; /* Space between grid items */
}

.home-block-container {
    width: 100%; /* Full width of the grid column */
    gap: 20px; /* Space between grid items */
}

.home-content {
    background-color: #069; /* Solid background color */
    color: white; /* White font color */
    padding: 20px; /* Inner spacing */
    border-radius: 5px; /* Optional: rounded corners */
    margin-bottom: 10px;
}


.home-content a {
  color: white;
  font-weight: bold;
}

.home-content table {
    background-color: white;
    color: black;
}

.home-links-content-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 20px; /* Space between grid items */
    width: 100%; /* Fit the grid width to its content */
    align-items: stretch; /* Stretches items to fill the container (this doesn't seem to work) */
}
.analysis-content {
    background-color: #993399; /* Solid background color */
    color: white; /* White font color */
    padding: 20px; /* Inner spacing */
    border-radius: 5px; /* Optional: rounded corners */
    min-height: 100px; /* Set this so blocks match their height to a minimum */
}

.home-block-container a {
  color: white;
  text-decoration: none;
}

.analysis-content h2 {
    margin-top: 0; /* Removes the top margin */
    padding-top: 0px; /* Optional: Adds some padding at the top if needed */
    text-align: center; /* Optional: center the text */
 }

/* Common styles for all content blocks inside the grid */
.home-links-content-container div {
    background-color: #993399; /* Solid background color */
    color: white; /* White font color */
    padding: 20px; /* Inner spacing */
    border-radius: 5px; /* Optional: rounded corners */
    min-height: 100px; /* Set this so blocks match their height to a minimum */
}

.home-links-content-container a {
  color: white;
  text-decoration: none;
}

.home-links-content-container h2 {
    margin-top: 0; /* Removes the top margin */
    padding-top: 0px; /* Optional: Adds some padding at the top if needed */
    text-align: center; /* Optional: center the text */
 }

.error-text {
    color: #F4C7C3;
    font-weight: bold;
}

.text-content-container {
    display: grid;
    grid-template-columns: 1fr; /* Single column layout */
    justify-items: center; /* Center grid items horizontally */
    gap: 20px; /* Space between grid items */
    width: 80%;
}

.inner-container {
    width: 100%; /* Adjust as needed */
    justify-content: left;
}

.content-container {
    display: grid;
    grid-template-columns: 1fr; /* Single column layout */
    justify-items: center; /* Stretch grid items to fill the container width */
    gap: 20px; /* Space between rows */
    margin: auto; /* Center the entire container on the page */
    width: 90%;
}

.content-container p {
    max-width: 100%;
}

.content-container a {
  color: white;
  font-weight: bold;
}

.content-container div {
    border-radius: 5px; /* Optional: rounded corners */
}

.text-row, .image-row {
    background-color: #993399; /* Background color for text rows */
    padding: 20px; /* Padding around the content */
    color: white; /* White text for text rows */
    text-align: left; /* Left-align the text */
    max-width: 75%;
}

.text-row table {
    background-color: white;
    color: black;
}

.alt-color {
    background-color: #069;
}

.image-row {
    background-color: #f0f0f0; /* Different background color for the image row */
    display: flex;
    justify-content: center; /* Center images within the row */
    gap: 10px; /* Gap between images */
    align-items: center; /* Align images vertically */
    overflow: hidden; /* Prevents images from overflowing */
}

.image-row img {
    width: 100%; /* Set the width to fill the container */
    max-width: calc(50% - 10px); /* Max width of each image */
    height: auto; /* Maintain aspect ratio */
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%; /* Percentage for 16:9 aspect ratio */
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}
